export const IssueList = [
  {
    title: '運動不足',
    text: '忙しさを理由に、最近お散歩もドッグランもあまり行けていない・・・',
    image: 'issue01.webp',
    imageWidth: 260,
  },
  {
    title: 'メンタル',
    text: '仕事で留守にしてしまいがち。\n寂しい思いをこれ以上させたくない・・・',
    image: 'issue02.webp',
    imageWidth: 261,
  },
  {
    title: '営業時間',
    text: '朝早かったり、帰りが遅くなると、\n送迎が営業時間に間に合わない・・・',
    image: 'issue03.webp',
    imageWidth: 148,
  },
  {
    title: '老後や病気',
    text: 'いざ何かあった時どうしよう・・・\n心配で外出中もそわそわ',
    image: 'issue04.webp',
    imageWidth: 258,
  },
  {
    title: '急用事',
    text: '急な外出で遅くなったり、\n帰って来れないこともしばしば・・・',
    image: 'issue05.webp',
    imageWidth: 220,
  },
  {
    title: '情報収集',
    text: 'ちょっとしたこと、同じ境遇の仲間に\n聞いてみたい・・・',
    image: 'issue06.webp',
    imageWidth: 258,
  },
  {
    title: '人見知り・怖がり',
    text: '日頃から他のペットや人との交流がないから、病院や来客の時も大変・・・',
    image: 'issue07.webp',
    imageWidth: 245,
  },
  {
    title: '信頼できるシッター',
    text: 'ペットシッターさんの良し悪しがわからない。知らない人に鍵を渡すのも抵抗あるし・・・',
    image: 'issue08.webp',
    imageWidth: 223,
  },
  {
    title: 'ご近所トラブル',
    text: 'うちの子、つい夜鳴きしちゃうし、鳴き声や足音、近隣への迷惑がかかっているかも・・・',
    image: 'issue09.webp',
    imageWidth: 250,
  },
];
