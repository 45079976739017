export const OurFutureSliderItemList = [
  {
    number: '01',
    title: '“頼れる存在”がマンションの1階に\n⽇常も、⾮⽇常も、安⼼サポート',
    contents: [
      'いつでも頼れる、顔馴染みの\n「常駐ペットシッター」',
      '送迎楽々。散歩のついでに寄っていける\nペットも馴染みやすい「⽝の保育園」',
      'ペットのセカンドハウスに。\nペットと遊べる「屋内スペース」',
    ],
    images: ['featureImage04.png', 'featureImage01.png'],
    imagePosition: 'bothEndsDiffSize',
  },
  {
    number: '02',
    title: '“分かり合える仲間”がそばにいる\nペットでつながる互助コミュニティ',
    contents: [
      'もちろんペットと一緒に。好きな時に立ち寄れる\n「入居者専用共用ラウンジ」',
      'ごはん、病院、お出かけ・・・ペットの情報をシェアし合おう。\n不用品のシェアや利用予約もできる「『P-man』専用アプリ」',
      '映画やスポーツ観戦、食事会。仲間と、ペットと一緒に。\n「併設シェアキッチン」',
    ],
    images: ['featureImage02.webp', 'featureImage03.webp'],
    imagePosition: 'bothEnds',
  },
  {
    number: '03',
    title: 'あれもこれも！ワガママが叶う。\n安心と楽しさが詰まったサービスが充実',
    contents: [
      '留守中も安心。お部屋の温度管理も遠隔で操作できる。\n「IoTサービス完備」',
      'マンション内に出向いてくれる「出張トリミング」\n「ドッグトレーナーDAY」',
      'ペットの体調変化に気づいた時に「24時間獣医師オンライン相談」',
      '⼊居者限定！「ペット⽤品割引」',
      '最新ペットグッズの「お試し体験」「ワークショップイベント」',
      'ペットとのお出かけに！「カーシェア」「（ペット乗せ）⾃転⾞\nシェア」「シェアバギー」',
    ],
  },
  {
    number: '04',
    title: 'ペット共⽣型マンションだから実現できる\nペットも、飼い主も、うれしい設備',
    contents: ['大型犬、多頭飼いもOK！'],
    facility01: ['スマートロック', 'リードフック', 'ドッグラン　など'],
    facility02: [
      '空調・照明の遠隔制御（IoT）',
      '⾶び出し防⽌柵',
      '滑りにくいフローリング',
      '防臭効果・⽖が⽴たない壁',
      'いたずら防⽌コンセントカバー',
      'ペットが⾃⼒で開けられない取⼿',
      '⾒守りカメラ　など',
    ],
    images: ['featureImage05.png'],
    imagePosition: 'left',
  },
];
