import type { RefObject } from 'react';
import { useEffect } from 'react';
import s from '../styles/common/inview.module.scss';
interface UseInViewOptions {
  threshold?: number;
  rootMargin?: string;
}

export const useInViewFadeUp = (refs: RefObject<HTMLElement | SVGSVGElement>[], options?: UseInViewOptions): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(s.afterFadeUp);
          } else {
            entry.target.classList.remove(s.afterFadeUp);
          }
        });
      },
      {
        root: null,
        rootMargin: options?.rootMargin || '-20% 0px -20% 0px',
        threshold: options?.threshold || 0,
      },
    );
    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [options, refs]);
};

export const useInViewFadeIn = (
  refs: RefObject<HTMLElement | SVGSVGElement | SVGPathElement>[],
  options?: UseInViewOptions,
): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(s.afterFadeIn);
          } else {
            entry.target.classList.remove(s.afterFadeIn);
          }
        });
      },
      {
        root: null,
        rootMargin: options?.rootMargin || '-20% 0px -20% 0px',
        threshold: options?.threshold || 0,
      },
    );
    refs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [options, refs]);
};
