export const WorriesItemList = [
  {
    text: '誰も居なくて外にも出れないと、本当につまらないし寂しいです。',
    spec: '（フレンチブルドック・1歳♂）',
    image: 'worries-dog.svg',
    imageWidth: 57,
  },
  {
    text: '病院という名の地獄。\nあそこに行くことで具合が悪くなるんだが（怒）',
    spec: '（サバトラ・７歳♂）',
    image: 'worries-cat.svg',
    imageWidth: 95,
  },
  {
    text: '温度＆湿度管理しっかりしてくれないと死活問題なわけよ、マジで。毛が無いんだから。',
    spec: '（エボシカメレオン・3歳♀）',
    image: 'worries-came.svg',
    imageWidth: 113,
  },
  {
    text: '老いぼれて気力で生きてます。孤独に留守番するのは老体には堪える。',
    spec: '（ミニレッキス・8歳♂）',
    image: 'worries-rabbit.svg',
    imageWidth: 78,
  },
  {
    text: 'ワタクシ、夜行性なんです',
    spec: '（チンチラ・5歳♀）',
    image: 'worries-chinchilla.svg',
    imageWidth: 82,
  },
  {
    text: '持病があるので内心覚悟はしているんですが、ひとりになるとやっぱり不安でたまらないの。',
    spec: '（スピッツ・7歳♂）',
    image: 'worries-pome.svg',
    imageWidth: 82,
  },
];
