import React from 'react';
/** lib,const **/
import classNames from 'classnames';
import { REGISTER_FORM_URL } from 'const';
/** styles, images **/
import s from '../../styles/molecules/lead-contents.module.scss';
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';

type Props = {
  className?: string;
  title?: string;
  gaActionName?: string;
  children: React.ReactNode;
};
export const LeadContents: React.FC<Props> = ({ className, title, children, gaActionName }) => {
  const clickBtn = () => {
    if (typeof window !== 'undefined') {
      window.gtag('event', 'click', {
        action: gaActionName,
      });
    }
    window.open(REGISTER_FORM_URL, '_blank');
  };
  return (
    <div className={classNames(className, s.LeadContentsWrapper)}>
      {title && <p className={s.title}>{title}</p>}
      <div className={s.text}>{children}</div>

      <button className={s.link} onClick={clickBtn}>
        サポーター登録（無料）
        <GreenCircleArrow className={s.icon} />
      </button>
      <div className={s.baloon}>
        サポーター登録2700人突破！<span>※11月1日時点</span>
      </div>
    </div>
  );
};
