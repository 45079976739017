import React from 'react';
import { Link } from 'react-router-dom';
/** lib,const **/
import classNames from 'classnames';
import { REGISTER_FORM_URL } from 'const';
/** styles,images **/
import s from '../../styles/common/header.module.scss';
import MecLogo from 'assets/images/mec-logo.webp';
import { ReactComponent as LogoWhiteSvg } from 'assets/images/logo.svg';
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';

export const Header: React.FC = () => {
  const clickBtn = () => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'click', {
        action: 'サポーター登録ボタンクリック(ヘッダー)',
      });
    }
    window.open(REGISTER_FORM_URL, '_blank');
  };
  return (
    <header>
      <div className={s.inner}>
        <div className={s.logoBlock}>
          <Link to={'/#top'}>
            <LogoWhiteSvg className={s.logo} />
          </Link>
          <p className={s.pcOnly}>三菱地所が事業化検討中　ペットのコンシェルジュがいる賃貸マンション　P-man</p>
        </div>

        <div className={s.rightBlock}>
          <button className={classNames(s.link, s.pcOnly)} onClick={clickBtn}>
            サポーター登録はこちら
            <GreenCircleArrow className={s.icon} />
          </button>
          <a href="https://www.mec.co.jp/" target="_blank" rel="noreferrer">
            <img src={MecLogo} width={150} height={33} alt="三菱地所" />
          </a>
        </div>
      </div>
    </header>
  );
};
