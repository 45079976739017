import React from 'react';
import { Link } from 'react-router-dom';
/** const **/
import { CONTACT_FORM_URL } from 'const';
/** styles,images **/
import s from '../../styles/common/footer.module.scss';
import InstagramLogo from 'assets/images/instagram.webp';
import MecLogo from 'assets/images/mec-logo.webp';
import { ReactComponent as LogoWhiteSvg } from 'assets/images/logo.svg';

export const Footer: React.FC = () => {
  return (
    <footer className={s.footer}>
      <div className={s.logoBlock}>
        <Link to={'/#top'}>
          <LogoWhiteSvg className={s.logo} />
        </Link>
        <p>三菱地所のペットコンシェルジュがいる賃貸マンション Ｐ-man</p>
        <h5>本件は事業化検討段階のため内容が変更となる場合があります</h5>
        <a className={s.spOnly} href="https://www.instagram.com/pman.mec" target="_blank" rel="noreferrer">
          <div className={s.instaLink}>
            <img src={InstagramLogo} alt="Instagram" />
            FOLLOW US
          </div>
        </a>
        <div className={s.mecWrapper}>
          <a className={s.mecLogo} href="https://www.mec.co.jp/" target="_blank" rel="noreferrer">
            <img src={MecLogo} alt="三菱地所" />
          </a>
          <p>
            これまで、オフィスや住宅、ホテルなどの開発・運営を通じ、数多くの快適な空間と憩いの時間を提供してきた三菱地所が新たに手掛けるサービスです。
          </p>
        </div>
      </div>
      <div className={s.contents}>
        <nav>
          <ul>
            <li>
              <a href="https://www.mec.co.jp/privacy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>
              <Link to={'/terms'}>利用規約</Link>
            </li>
            <li>
              <a href={CONTACT_FORM_URL} target="_blank" rel="noreferrer">
                お問い合わせ
              </a>
            </li>
          </ul>
        </nav>
        <a className={s.pcOnly} href="https://www.instagram.com/pman.mec" target="_blank" rel="noreferrer">
          <div className={s.instaLink}>
            <img src={InstagramLogo} alt="Instagram" />
            FOLLOW US
          </div>
        </a>
        <p className={s.copyright}>COPYRIGHT © MITSUBISHI ESTATE CO., LTD. ALL RIGHTS RESERVED.</p>
      </div>
    </footer>
  );
};
