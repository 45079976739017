import React from 'react';

import { MainEquipmentList } from 'lib/equipments';

import { ContentsTitle } from '../molecules';
import { FadeUp } from 'components/organisms';

import s from '../../styles/organisms/equipment.module.scss';
import classNames from 'classnames';

export const Equipment: React.FC = () => {
  return (
    <div className={s.equipmentWrapper}>
      <FadeUp>
        <div className={s.contents}>
          <div className={s.titleRow}>
            <ContentsTitle
              className={s.contentsTitle}
              title="EQUIPMENT"
              subTitle={`ペットのための \nお部屋内・共用部の設備`}
            />
            <p className={classNames(s.note, s.pcOnly)}>※ 事業化に向け現在検討中の設備内容です</p>
          </div>
          <div className={s.mainList}>
            {MainEquipmentList.map((item, index) => (
              <div className={s.mainItem} key={index}>
                <img src={require(`assets/images/${item.image}`)} alt={item.name} />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
          <p className={classNames(s.note, s.spOnly)}>※ 事業化に向け現在検討中の設備内容です</p>
        </div>
      </FadeUp>
    </div>
  );
};
