import React from 'react';
/** lib **/
import classNames from 'classnames';
/** styles **/
import s from '../../styles/molecules/worries-item.module.scss';

type Props = {
  className?: string;
  text?: string;
  spec?: string;
  image?: string;
  imageWidth?: number;
};
export const WorriesItem: React.FC<Props> = ({ className, text, spec, image, imageWidth }) => {
  return (
    <div className={classNames(className, s.worriesItemWrapper)}>
      <p className={s.text} translate="no">
        {text}
      </p>
      <p className={s.spec} translate="no">
        {spec}
      </p>

      <img src={require(`assets/images/${image}`)} width={imageWidth} alt="ペットの悩み" />
    </div>
  );
};
