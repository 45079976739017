import React, { useEffect, useRef } from 'react';
/** lib, const **/
import { useInViewFadeUp, useInViewFadeIn } from 'lib/use-in-view';
import classNames from 'classnames';
import { SERVICE_NAME } from '../../const';
/** components **/
import { ContentsTitle } from '../molecules';
import { FadeUp, ParallaxItem } from 'components/organisms';
/* images */
import BgLogo from 'assets/images/bg-logo.svg';
/* styles */
import s from '../../styles/organisms/what-is-pman.module.scss';

export const WhatIsPman: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  const inViewRef3 = useRef<HTMLHeadingElement>(null);
  const inViewRef4 = useRef<HTMLHeadingElement>(null);
  const inViewRef5 = useRef<SVGPathElement>(null);
  const inViewRef6 = useRef<SVGSVGElement>(null);

  useInViewFadeUp([inViewRef1, inViewRef2, inViewRef4]);
  useInViewFadeIn([inViewRef3]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const svgAnimation = [inViewRef5, inViewRef6];

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(s.afterFadeInLogo);
        } else {
          entry.target.classList.remove(s.afterFadeInLogo);
        }
      });
    });
    svgAnimation.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      svgAnimation.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [svgAnimation]);
  return (
    <FadeUp>
      <div className={s.whatIsPmanWrapper}>
        <div className={s.inner}>
          <div className={s.beforeFadeUp} ref={inViewRef1}>
            <ContentsTitle className={classNames(s.contentsTitle)} title="WHAT’S P-man" subTitle="ピーマンとは" />
          </div>
          <div className={s.row}>
            <div className={classNames(s.beforeFadeUp, s.logoWrapper)} ref={inViewRef4}>
              <svg
                className={s.logo}
                width="161"
                height="95"
                viewBox="0 0 161 95"
                fill="none"
                ref={inViewRef6}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M74.7803 94.5083C71.2193 94.5083 68.329 91.618 68.329 88.057V65.191C68.329 61.4337 65.2697 58.3743 61.5123 58.3743C57.755 58.3743 54.6956 61.4337 54.6956 65.191V88.057C54.6956 91.618 51.8053 94.5083 48.2443 94.5083C44.6833 94.5083 41.793 91.618 41.793 88.057V65.191C41.793 54.317 50.6383 45.4717 61.5123 45.4717C72.3863 45.4717 81.2317 54.317 81.2317 65.191V88.057C81.2317 91.618 78.3414 94.5083 74.7803 94.5083Z"
                  fill="#1F1F1F"
                />
                {/* p */}
                <path
                  className={s.logoSvgAnimation}
                  ref={inViewRef5}
                  d="M15 78.4998V43C15 43 15.4977 37.4998 17.0012 34.4998C18.5047 31.4998 24.4992 25.5001 32.9992 25.5C37.4992 25.4999 41.9992 28 41.9992 28C45.9992 30.5 47.4129 32.0338 49 36C51.0008 41 51 48 50.5 53.5C50.1799 57.021 48 60 48 60C48 60 47.9402 60.2606 45.5 63C41.7132 67.2511 34.9992 68 31.9992 68C24.9992 68 15.4992 68 15.4992 68"
                />
                {/* 肉球1こめ */}
                <path
                  className={s.logoSvgAnimationDot01}
                  d="M7.35113 25.4684C11.411 25.4684 14.7023 22.1772 14.7023 18.1172C14.7023 14.0573 11.411 10.7661 7.35113 10.7661C3.29121 10.7661 0 14.0573 0 18.1172C0 22.1772 3.29121 25.4684 7.35113 25.4684Z"
                  fill="#00B66A"
                />
                {/* 肉球2こめ */}
                <path
                  className={s.logoSvgAnimationDot02}
                  d="M22.9683 15.1671C27.0282 15.1671 30.3194 11.8759 30.3194 7.81597C30.3194 3.75606 27.0282 0.464844 22.9683 0.464844C18.9084 0.464844 15.6172 3.75606 15.6172 7.81597C15.6172 11.8759 18.9084 15.1671 22.9683 15.1671Z"
                  fill="#00B66A"
                />
                {/* 肉球3こめ */}
                <path
                  className={s.logoSvgAnimationDot03}
                  d="M42.1871 15.1676C46.247 15.1676 49.5382 11.8764 49.5382 7.81646C49.5382 3.75654 46.247 0.465332 42.1871 0.465332C38.1271 0.465332 34.8359 3.75654 34.8359 7.81646C34.8359 11.8764 38.1271 15.1676 42.1871 15.1676Z"
                  fill="#00B66A"
                />
                {/* 肉球４こめ */}
                <path
                  className={s.logoSvgAnimationDot04}
                  d="M58.5386 25.4684C62.5985 25.4684 65.8898 22.1772 65.8898 18.1172C65.8898 14.0573 62.5985 10.7661 58.5386 10.7661C54.4787 10.7661 51.1875 14.0573 51.1875 18.1172C51.1875 22.1772 54.4787 25.4684 58.5386 25.4684Z"
                  fill="#00B66A"
                />
                {/* Mの右 */}
                <path
                  d="M74.7795 94.508C71.2184 94.508 68.3281 91.6177 68.3281 88.0566V64.629C68.3281 53.755 77.1735 44.9097 88.0475 44.9097C98.9215 44.9097 107.767 53.755 107.767 64.629V87.4949C107.767 91.056 104.877 93.9463 101.315 93.9463C97.7544 93.9463 94.8642 91.056 94.8642 87.4949V64.629C94.8642 60.8717 91.8048 57.8123 88.0475 57.8123C84.2901 57.8123 81.2308 60.8717 81.2308 64.629V88.0566C81.2308 91.6177 78.3405 94.508 74.7795 94.508Z"
                  fill="#1F1F1F"
                />
                {/* aのうえ */}
                <path
                  d="M101.526 94.508C97.9645 94.508 95.0742 91.6177 95.0742 88.0566V64.629C95.0742 53.755 103.92 44.9097 114.794 44.9097C125.668 44.9097 134.513 53.755 134.513 64.629V87.4949C134.513 91.056 131.623 93.9463 128.062 93.9463C124.501 93.9463 121.61 91.056 121.61 87.4949V64.629C121.61 60.8717 118.551 57.8123 114.794 57.8123C111.036 57.8123 107.977 60.8717 107.977 64.629V88.0566C107.977 91.6177 105.087 94.508 101.526 94.508Z"
                  fill="#1F1F1F"
                />
                {/* nの右 */}
                <path
                  d="M128.014 94.508C124.453 94.508 121.562 91.6177 121.562 88.0566V64.629C121.562 53.755 130.408 44.9097 141.282 44.9097C152.156 44.9097 161.001 53.755 161.001 64.629V87.4949C161.001 91.056 158.111 93.9463 154.55 93.9463C150.989 93.9463 148.099 91.056 148.099 87.4949V64.629C148.099 60.8717 145.039 57.8123 141.282 57.8123C137.524 57.8123 134.465 60.8717 134.465 64.629V88.0566C134.465 91.6177 131.575 94.508 128.014 94.508Z"
                  fill="#1F1F1F"
                />
                {/* Aの真ん中 */}
                <path
                  d="M126.142 82.1124H102.802C99.9388 82.1124 97.6211 79.7947 97.6211 76.9317C97.6211 74.0687 99.9388 71.751 102.802 71.751H126.142C129.005 71.751 131.323 74.0687 131.323 76.9317C131.323 79.7947 129.005 82.1124 126.142 82.1124Z"
                  fill="#1F1F1F"
                />
              </svg>
            </div>
            <p className={s.beforeFadeUp} ref={inViewRef2}>
              真の「ペットと人の共生」
              <br className={s.spOnly} />
              マンションを目指し、 <br />
              三菱地所が事業化検討中の
              <br className={s.spOnly} />
              プロジェクトです
            </p>
          </div>
          <div className={classNames(s.beforeFadeIn, s.info)} ref={inViewRef3}>
            <h4>PET + Mansion = P-man</h4>
            <p>
              PETの「P」とMANSION（マンション）の「man」を合わせ、さらに親しみやすい名前にしたいという思いから「P-man（ピーマン）」と名付けました。
            </p>
          </div>
        </div>
        <ParallaxItem className={s.parallaxBg}>
          <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
        </ParallaxItem>
      </div>
    </FadeUp>
  );
};
