/* eslint-disable array-callback-return */
import React from 'react';
import { ContentsTitle, FaqItem } from '../molecules';
import { FadeUp } from 'components/organisms';
import { FaqList } from 'lib/faq';

import s from '../../styles/organisms/faq.module.scss';

export const Faq: React.FC = () => {
  return (
    <FadeUp>
      <div className={s.faqWrapper}>
        <ContentsTitle className={s.contentsTitle} title="FAQ" subTitle="よくある質問" />
        <div className={s.contents}>
          {FaqList.map((item, index) => (
            <FaqItem className={s.row} key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </FadeUp>
  );
};
