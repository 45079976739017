import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Header, Footer } from 'components/organisms';

type Props = {
  children: React.ReactNode;
};
export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Helmet
        title={'P-man 三菱地所が事業化検討中 ペットのコンシェルジュがいる賃貸マンション'}
        meta={[
          {
            name: 'description',
            content: '真の「ペットと人の共生」マンションを目指し、三菱地所が事業化検討中のプロジェクトです',
          },
          { property: 'og:title', content: 'P-man 三菱地所が事業化検討中 ペットのコンシェルジュがいる賃貸マンション' },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: 'https://p-man.mec.co.jp/' },
          { property: 'og:image', content: 'https://p-man.mec.co.jp/ogp.png' },
          {
            property: 'og:description',
            content: '真の「ペットと人の共生」マンションを目指し、三菱地所が事業化検討中のプロジェクトです',
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:image', content: 'https://p-man.mec.co.jp/ogp.png' },
          {
            name: 'twitter:site',
            content: '真の「ペットと人の共生」マンションを目指し、三菱地所が事業化検討中のプロジェクトです',
          },
        ]}
      >
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BTQ7CE5MB4"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-BTQ7CE5MB4', { send_page_view: false });
            `}
        </script>
      </Helmet>

      <Header />
      {children}
      <Footer />
    </>
  );
};
export default Layout;
