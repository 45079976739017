import React, { useEffect, useRef, useState } from 'react';

interface ParallaxProps {
  className?: string;
  children: React.ReactNode;
  targetFactor?: number;
}

export const ParallaxItem: React.FC<ParallaxProps> = ({ className, targetFactor, children }) => {
  const domRef = useRef<HTMLDivElement>(null);
  const [offsetY, setOffsetY] = useState(0);

  const onScroll = () => {
    if (domRef.current !== null) {
      const scrollY = window.pageYOffset; // scroll量を取得
      setOffsetY(scrollY * (targetFactor ? targetFactor : 0.15) * -1);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
  });

  return (
    <div
      className={className}
      ref={domRef}
      style={{
        transform: `translateY(${offsetY}px)`,
      }}
    >
      {children}
    </div>
  );
};
