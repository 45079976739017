import React, { useState } from 'react';
/** lib **/
import classNames from 'classnames';
/** style,images **/
import s from '../../styles/molecules/faq-item.module.scss';
import { ReactComponent as ArrowTop } from 'assets/images/arrow-top.svg';

type Props = {
  className?: string;
  question: string;
  answer: string;
};
export const FaqItem: React.FC<Props> = ({ className, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames(className, s.faqItemWrapper, isOpen ? s.isOpen : ``)} onClick={() => setIsOpen(!isOpen)}>
      <div className={s.question}>
        Q. {question}
        <ArrowTop className={s.icon} />
      </div>
      <div className={classNames(s.answer, isOpen ? s.isOpen : ``)}>{answer}</div>
    </div>
  );
};
