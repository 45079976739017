import React from 'react';
/** lib **/
import classNames from 'classnames';
/** style,images **/
import s from '../../styles/molecules/contents-title.module.scss';

type Props = {
  className?: string;
  title?: string;
  subTitle?: string;
};
export const ContentsTitle: React.FC<Props> = ({ className, title, subTitle }) => {
  return (
    <div className={classNames(className, s.ContentsTitleWrapper)}>
      <h3>{title}</h3>
      <p>{subTitle}</p>
    </div>
  );
};
