import React, { useRef } from 'react';
/** lib **/
import classNames from 'classnames';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MemberComment } from 'lib/member';
/** components **/
import { ContentsTitle } from '../molecules';
import { FadeUp } from 'components/organisms';
/** styles,images **/
import s from '../../styles/organisms/member.module.scss';
import { ReactComponent as BlackCircleArrow } from 'assets/images/black-circle-arrow.svg';

export const Member: React.FC = () => {
  const swiperRef = useRef<SwiperCore>();
  const SwiperButtonPrev = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slidePrev()}>
        <BlackCircleArrow className={classNames(s.navButton, s.prevButton)} />
      </button>
    );
  };

  const SwiperButtonNext = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slideNext()}>
        <BlackCircleArrow className={classNames(s.navButton, s.nextButton)} />
      </button>
    );
  };
  return (
    <div className={s.memberWrapper}>
      <FadeUp delay={0}>
        <ContentsTitle className={s.contentsTitle} title="MEMBER" subTitle={`事業開発者の思い`} />
      </FadeUp>
      <FadeUp>
        <Swiper
          className={s.sliderWrapper}
          onBeforeInit={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={54}
          speed={700}
          slidesPerView={1}
          loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 2,
            },
          }}
        >
          {MemberComment.map((item, index) => (
            <SwiperSlide className={s.item} key={index}>
              <div className={s.imageWrapper}>
                <img src={require(`assets/images/${item.image}`)} alt={item.name} />
                <p className={s.position}>{item.position}</p>
                <p className={s.name}>{item.name}</p>
              </div>
              <div className={s.text}>
                <p>{item.text}</p>
              </div>
            </SwiperSlide>
          ))}

          <div className={s.navWrapper}>
            <SwiperButtonPrev />
            <SwiperButtonNext />
          </div>
        </Swiper>
      </FadeUp>
    </div>
  );
};
