export const MainEquipmentList = [
  {
    name: 'スマートロック',
    image: 'equipment01.webp',
  },
  {
    name: 'リードフック',
    image: 'equipment02.webp',
  },
  {
    name: 'ドッグラン',
    image: 'equipment03.webp',
  },
  {
    name: '飛び出し防止柵',
    image: 'equipment04.webp',
  },
  {
    name: '滑りにくい\nフローリング',
    image: 'equipment05.webp',
  },
  {
    name: '防臭効果あり\n爪が立たない壁',
    image: 'equipment06.webp',
  },
  {
    name: '空調の遠隔操作',
    image: 'equipment07.webp',
  },
  {
    name: 'コンセントカバー',
    image: 'equipment08.webp',
  },
  {
    name: 'ペットが自力で\n開けられない取手',
    image: 'equipment09.webp',
  },
  {
    name: '見守りカメラ',
    image: 'equipment010.webp',
  },
];

export const OtherEquipmentList = [
  'シッターも預かりも、特別価格の月額制',
  'ペットの老後も、変わらず一緒に。老犬・老猫ホームもいいけど、いつもの場所で、いつもの通りに',
  'ペットの緊急時・ペットオーナーの体調不良も、駆け付け＆サポート',
  'ペットの最新グッズのモニターイベントや特別価格販売企画',
  '毎日使わないものは、シェアで効率的に経済的に',
];
