export const SupporterVoiceList = [
  {
    spec: '40代 女性 (フレンチブルドッグ)',
    image: 'supporter01.webp',
    text: 'ペットを飼っている人たちには夢のようなマンションだと思います。このようなマンションが沢山できることを願っております。',
  },
  {
    spec: '40代男性（ラブラドールレトリバー）',
    image: 'supporter02.webp',
    text: '大型犬を飼っていますが、大型犬が飼えるマンションの引越し先が無くて困っております。大型犬を飼えるマンション増えると非常に助かります。',
  },
  {
    spec: '30代 ⼥性 (プードル)',
    image: 'supporter03.webp',
    text: 'ペットを飼育する上で、たくさんの悩みごとがありますが、少しでも悩みが解消するマンションがあればと思っていました。是非、実現してほしいです。',
  },
  {
    spec: '40代 男性 (フレンチブルドッグ)',
    image: 'supporter04.webp',
    text: '愛犬家にとって理想のマンションができるのであれば、是非応援させていただきます。',
  },
  {
    spec: '30代 ⼥性 (ゴールデンレトリバー)',
    image: 'supporter05.webp',
    text: '近所にペットホテルも無く、シッターさんが常駐しているマンションであれば、気兼ねなく外出できると思います。いち早くマンションを作ってほしいです。',
  },
  {
    spec: '30代 ⼥性 (ダックスフンド)',
    image: 'supporter06.webp',
    text: 'マンションに⽝の保育園があれば、昼間仕事に⾏っている間に、しつけを覚えることができて、お迎えも必要なし。すごく助かります！！',
  },
  {
    spec: '30代 ⼥性 (ラグドール)',
    image: 'supporter07.webp',
    text: 'ペットを⼀⼈にさせることが多いのでペットシッターさんがついているマンションであれば、安⼼して、お留守番をさせることができる理想のマンションだと思います。',
  },
  {
    spec: '20代 女性（ミヌエット）',
    image: 'supporter011.webp',
    text: '猫を飼っており散歩等で外出しないため猫好きコミュニティーが広がらず、猫好き同士で情報交換と思っていました。猫好きの方と交流ができたらと思っています！',
  },
  {
    spec: '30代 女性（ミックス）',
    image: 'supporter08.webp',
    text: 'ペットが健康に楽しく暮らしてくれることが自分の幸せに直結するので、ペットファーストで考えられたマンションに住めたら最高です！',
  },
  {
    spec: '30代 ⼥性 (ミニチュアダックスフンド)',
    image: 'supporter010.webp',
    text: '夫婦共に外出が多くお散歩も不足がちなのが気になっています。マンション内で預けられて何かの時にも頼れる人がいる環境はとてもありがたいです！',
  },
  {
    spec: '30代 女性（ミックス）',
    image: 'supporter013.png',
    text: '「P-man」は猫の健康と幸せを第一に考える私にとってぴったりのお家です。キャットウォークなど設備が整ったマンションがあると嬉しいですね。',
  },
  {
    spec: '20代 男性 (ミヌエット)',
    image: 'supporter012.webp',
    text: '持ち家で犬猫を飼ったことがありますが、賃貸マンションでペットを飼う決意できなかった過去があります。是非とも、この構想を実現してもらいたいと思います。',
  },
  {
    spec: '40代 女性 (マルプー)',
    image: 'supporter014.png',
    text: '仕事や外出でペットだけで留守番が必要な時に、常駐のペットシッターや保育園があったらとても心強いです。人もペットも安心して住める環境が理想です。ぜひ実現して欲しいと思います。',
  },
];
