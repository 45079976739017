import React, { useEffect } from 'react';
/** lib **/
import { WorriesItemList } from 'lib/worries';
/** components **/
import { Layout, FadeUp } from 'components/organisms';
import { ContentsTitle, WorriesItem } from 'components/molecules';
/** styles **/
import s from '../styles/pages/worries.module.scss';

export const Worries: React.FC = () => {
  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: '/worries',
      });
    }
  }, []);

  return (
    <Layout>
      <div className={s.worriesPageWrapper}>
        <FadeUp delay={0}>
          <ContentsTitle className={s.contentsTitle} title="WORRIES" subTitle="ペットの悩み" />
        </FadeUp>
        <FadeUp delay={0}>
          <div className={s.worriesList}>
            {WorriesItemList.map((item, index) => (
              <WorriesItem
                key={index}
                text={item.text}
                spec={item.spec}
                imageWidth={item.imageWidth}
                image={item.image}
              />
            ))}
          </div>
        </FadeUp>
      </div>
    </Layout>
  );
};
