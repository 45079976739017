import React from 'react';
/** lib **/
import classNames from 'classnames';
/** styles, images **/
import { ReactComponent as Pad } from 'assets/images/pad.svg';
import s from '../../styles/molecules/our-future-slider-item.module.scss';

type Props = {
  className?: string;
  number?: string;
  title?: string;
  contents: string[];
  images?: string[];
  imagePosition?: 'left' | 'bothEnds' | 'bothEndsDiffSize';
  facility01?: string[];
  facility02?: string[];
};
export const OurFutureSliderItem: React.FC<Props> = ({
  className,
  number,
  title,
  contents,
  images,
  imagePosition,
  facility01,
  facility02,
}) => {
  const imagePositionMap = {
    left: s.left,
    bothEnds: s.bothEnds,
    bothEndsDiffSize: s.bothEndsDiffSize,
  };
  const imagePositionStyle = (imagePosition && imagePositionMap[imagePosition]) || '';

  return (
    <div className={classNames(className, s.OurFutureSliderItemWrapper)}>
      <div className={s.inner}>
        <div className={s.contentsNumber}>
          <span>POINT</span>
          <p>{number}</p>
        </div>
        <h4>{title}</h4>
        <ul>
          {contents.map((content, index) => (
            <li key={index}>
              <Pad className={s.icon} />
              <h5>{content}</h5>
            </li>
          ))}
        </ul>
        {images && (
          <div className={classNames(s.imageWrapper, imagePositionStyle)}>
            {images.map((image, index) => (
              <img key={index} src={require(`assets/images/${image}`)} alt={title} />
            ))}
          </div>
        )}
        <div className={s.facilityWrapper}>
          {facility01 && (
            <ul>
              <h6>共用部設備</h6>
              {facility01.map((item, index) => (
                <li key={index}>・{item}</li>
              ))}
            </ul>
          )}
          {facility02 && (
            <ul>
              <h6>専有部（お部屋内）</h6>
              {facility02.map((item, index) => (
                <li key={index}>・{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
