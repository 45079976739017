import React, { useRef } from 'react';
/** lib, const **/
import classNames from 'classnames';
import { useInViewFadeUp, useInViewFadeIn } from 'lib/use-in-view';
import { SERVICE_NAME } from '../../const';
/** components **/
import { FadeUp, ParallaxItem } from 'components/organisms';
/** styles **/
import s from '../../styles/organisms/possible-Items.module.scss';
import BgLogo from 'assets/images/bg-logo.svg';

export const PossibleItems: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  const inViewRef3 = useRef<HTMLHeadingElement>(null);
  const inViewRef4 = useRef<HTMLHeadingElement>(null);
  const inViewRef5 = useRef<HTMLHeadingElement>(null);
  useInViewFadeUp([inViewRef1, inViewRef3, inViewRef4, inViewRef5]);
  useInViewFadeIn([inViewRef2]);
  return (
    <FadeUp>
      <div className={s.possibleItemsWrapper}>
        <h2 className={s.beforeFadeUp} ref={inViewRef1}>
          P-manだから叶う、安心なくらし
        </h2>
        <div className={classNames(s.beforeFadeIn, s.inner)} ref={inViewRef2}>
          <div className={s.intro}>
            <div className={s.item}>
              まるで“コンシェルジュ”
              <br />
              「常駐ペットシッター」
            </div>
            <p>×</p>
            <div className={s.item}>
              共用ラウンジや専用アプリで​
              <br />
              『P-man』コミュニティ
            </div>
          </div>
          <p className={s.leadText}>
            ペットを愛でよう。
            <br className={s.spOnly} />
            暮らしを楽しもう。
          </p>
          <div className={s.row}>
            <div className={classNames(s.beforeFadeUp, s.item)} ref={inViewRef3}>
              <div className={s.num}>1</div>
              <h3>いっぱい使える!</h3>
              <p>
                ペットシッター・⽝の保育園が、
                <br />
                <span>⽉20⽇・週5日まで利⽤可能！</span>
                <br />
                仕事が忙しい⽅も安⼼。保育園送迎も入居者限定でラクチン＆クイックに！
              </p>
            </div>
            <div className={classNames(s.beforeFadeUp, s.delay01, s.item)} ref={inViewRef4}>
              <div className={s.num}>2</div>
              <h3>いつでも使える!</h3>
              <p>
                いざという時も、ちょっと⾒守って欲しい時も、<span>頼れる味⽅がいっぱい！</span>
                <br />
                ペットシッターなどの専門家はもちろん、飼い主同士のつながりがあるのも安心。
              </p>
            </div>
            <div className={classNames(s.beforeFadeUp, s.delay02, s.item)} ref={inViewRef5}>
              <div className={s.num}>3</div>
              <h3>いつまでも使える!</h3>
              <p>
                ペットが⽼齢や病気になっても、安⼼できる<span>慣れ親しんだ環境でずっと</span>過ごせる。
                <br />
                「P-man」なら移動にも負担がかかりません。
              </p>
            </div>
          </div>
        </div>
        <ParallaxItem className={s.parallaxBg}>
          <img src={BgLogo} alt={SERVICE_NAME} />
        </ParallaxItem>
      </div>
    </FadeUp>
  );
};
