import React, { useEffect } from 'react';
/** lib **/
import { useScrollToAnchor } from 'lib/use-scroll-anker-link';
/** components **/
import {
  KeyVisual,
  WhatIsPman,
  IssuesInPetOwnership,
  PossibleItems,
  OurFutures,
  Worries,
  Equipment,
  AreaFloorPlanRent,
  Concept,
  Member,
  OurPartner,
  SupporterVoice,
  Faq,
  Layout,
} from 'components/organisms';
/** styles **/
import s from '../styles/pages/top.module.scss';

export const Top: React.FC = () => {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });

  useEffect(() => {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: '/',
      });
    }
  }, []);

  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  return (
    <Layout>
      <div id="top" className={s.topWrapper}>
        <KeyVisual />
        <WhatIsPman />
        <IssuesInPetOwnership />
        <PossibleItems />
        <OurFutures />
        <Worries />
        <Equipment />
        <AreaFloorPlanRent />
        <Concept />
        <Member />
        <OurPartner />
        <SupporterVoice />
        <Faq />
      </div>
    </Layout>
  );
};
