import { motion } from 'framer-motion';
import React from 'react';

type Props = {
  delay?: number;
  duration?: number;
  children: React.ReactNode;
};

export const FadeUp: React.FC<Props> = ({ delay = 0, duration = 0.8, children }) => {
  return (
    <motion.div
      viewport={{ once: true }}
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        delay: delay,
        duration: duration,
        ease: 'easeOut',
      }}
    >
      {children}
    </motion.div>
  );
};
