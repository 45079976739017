import React, { useRef, useState } from 'react';
/** lib **/
import classNames from 'classnames';
import { OurFutureSliderItemList } from 'lib/our-futures-data';
import { useInViewFadeIn, useInViewFadeUp } from 'lib/use-in-view';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SERVICE_NAME } from '../../const';
// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
/** components **/
import { ContentsTitle, OurFutureSliderItem, LeadContents } from '../molecules';
import { ParallaxItem } from 'components/organisms';
/** styles,images **/
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';
import { ReactComponent as GreenCircleArrowPrev } from 'assets/images/green-circle-arrow-prev.svg';
import s from '../../styles/organisms/our-futures.module.scss';
import BgLogo from 'assets/images/bg-logo.svg';

export const OurFutures: React.FC = () => {
  const swiperRef = useRef<SwiperCore>();
  const [activePoint, setActivePoint] = useState(1);
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  const inViewRef3 = useRef<HTMLHeadingElement>(null);
  const inViewRef4 = useRef<HTMLHeadingElement>(null);
  const inViewRef5 = useRef<HTMLHeadingElement>(null);
  const inViewRef6 = useRef<HTMLHeadingElement>(null);
  const inViewRef7 = useRef<HTMLHeadingElement>(null);
  useInViewFadeIn([inViewRef1]);
  useInViewFadeUp([inViewRef2, inViewRef3, inViewRef4, inViewRef5, inViewRef6, inViewRef7], {
    rootMargin: '-10% 0px -10% 0px',
  });

  useInViewFadeUp([inViewRef7], {
    rootMargin: '-20% 0px -20% 0px',
  });
  const SwiperButtonPrev = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slidePrev()}>
        <GreenCircleArrowPrev className={classNames(s.navButton, s.prevButton)} />
      </button>
    );
  };

  const SwiperButtonNext = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slideNext()}>
        <GreenCircleArrow className={classNames(s.navButton, s.nextButton)} />
      </button>
    );
  };

  const SwiperPagination = (index: number, point: number, text: string): JSX.Element => {
    let curentRef;
    switch (point) {
      case 1:
        curentRef = inViewRef5;
        break;
      case 2:
        curentRef = inViewRef2;
        break;
      case 3:
        curentRef = inViewRef3;
        break;
      case 4:
        curentRef = inViewRef4;
        break;
      default:
        curentRef = null;
    }
    return (
      <div
        className={
          activePoint === point
            ? classNames(s.active, s.pagenationItem, s.beforeSlideInToLeft)
            : classNames(s.pagenationItem, s.beforeSlideInToLeft)
        }
        ref={curentRef ? curentRef : inViewRef5}
        onClick={() => {
          swiperRef.current?.slideTo(index);
          setActivePoint(point);
        }}
      >
        <p className={s.num}>POINT {point}</p>
        <p className={s.text}>{text}</p>
      </div>
    );
  };
  return (
    <div className={s.ourFuturesWrapper}>
      <div className={s.beforeFadeIn} ref={inViewRef1}>
        <ContentsTitle className={s.contentsTitle} title={`OUR\nFEATURES`} subTitle="P-manの特徴" />
      </div>
      <div className={classNames(s.beforeFadeUp, s.inner)} ref={inViewRef7}>
        <div className={classNames(s.pagenationWrapper, s.pcOnly)}>
          {SwiperPagination(1, 1, '頼れる存在がマンションの1階に')}
          {SwiperPagination(2, 2, '“分かり合える仲間”がそばにいる')}
          {SwiperPagination(3, 3, '安心と楽しさが詰まったサービスが充実')}
          {SwiperPagination(0, 4, 'ペットも、飼い主も、うれしい設備')}
        </div>

        <div className={s.navWrapper}>
          <SwiperButtonPrev />
          <SwiperButtonNext />
        </div>
        <Swiper
          className={s.swiperContainer}
          onBeforeInit={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          speed={1000}
          slidesPerView={1}
          centeredSlides
          loop
          effect={'fade'}
          modules={[EffectFade, Navigation, Pagination]}
        >
          {OurFutureSliderItemList.map((item, index) => (
            <SwiperSlide key={index} className={s.swiperSlideItem}>
              <OurFutureSliderItem
                number={item.number}
                title={item.title}
                contents={item.contents}
                images={item.images}
                imagePosition={item.imagePosition as 'left' | 'bothEnds' | 'bothEndsDiffSize'}
                facility01={item.facility01}
                facility02={item.facility02}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={s.beforeFadeUp} ref={inViewRef6}>
        <LeadContents gaActionName="サポーター登録ボタンクリック(P-manの特徴)">
          <h3>あなたの ”あったらいいな” が現実になるかも？</h3>
          <p>
            応募にはサポーター登録をしていただく必要がございます。サポーター様にはイベントのご案内やいち早く最新情報をお届けします。
          </p>
          <p>※ 提供有無は物件によって異なります</p>
        </LeadContents>
      </div>

      <ParallaxItem className={s.parallaxBg}>
        <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
      </ParallaxItem>
    </div>
  );
};
