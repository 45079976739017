export const FaqList = [
  {
    question: '通常の「ペット可」のマンションとの違いは何でしょうか？',
    answer:
      'ペットのコンシェルジュがいるマンション「P-man」では、”ペットとの共生”を前提としており、単にペットが飼えるだけでなく、ペットと一緒に快適に暮らすことを目的とし、さらに飼い主（入居者）同士の互助コミュニティを実現するマンションです。\n「P-man」では、ペット専用の遊び場や共用ラウンジ、ペット預かりやペットシッターなど、ペットとその飼い主が共に楽しみ、安心して暮らせる設備・サービスを提供します。さらに、ペットに関するイベントやワークショップ等も開催し、飼い主（入居者）同士でも情報交換ができ、交流を深めることができる点が特徴です。',
  },
  {
    question: '『P-man』のサポーターに登録すると、どのような特典があるのでしょうか？',
    answer:
      '現在事業化検討中のため、サポーター様にはイベント案内や、建築が決まった場合の優先情報提供を予定しています。（物件によって優先情報をご案内ができない場合がございます。）\n企画に対するご意見交換会も実施予定ですので、ぜひご参加ください。',
  },
  {
    question: '『P-man』のサポーター登録に費用はかかりますか？',
    answer:
      '無料でご登録いただけます。また、イベント・意見交換会等に関しても参加費用は発生しません。\n※交通費等は自己負担となります。',
  },
  {
    question: 'ペットを飼っていなくても『P-man』のサポーターに登録できますか？',
    answer:
      'はい、ペットを飼っていなくても、ペットに関心のある方や将来的に飼いたいと考えている方も大歓迎です。皆さんの意見やアイデアが、より良いペット共生型マンションの実現に役立ちますので、ぜひご参加ください。',
  },
  {
    question: '東京都・神奈川県・千葉県・埼玉県以外に住んでいるのですが、『P-man』のサポーターに登録はできますか。',
    answer:
      'はい、東京都・神奈川県・千葉県・埼玉県以外にお住まいの方でも大歓迎です。\n現在は1都3県エリアでの建築を考えていますが、将来的には全国展開も検討しています。',
  },
  {
    question:
      '障害を持っているペットを飼っているため、他の方と異なった視点での要望が多くなりますが、『P-man』のサポーターに登録できますか？',
    answer:
      'はい、ペット共生に関する様々な視点でのご意見をいただきたいと考えています。ぜひご意見やご状況をお聞かせください。',
  },
  {
    question: 'マンションはいつ頃入居できるのでしょうか？',
    answer:
      '誠に恐れ入りますが、『P-man』は事業化検討の段階のため、入居時期を確約することができません。皆さんのご意見をもとに事業化を進めてまいりますので、ぜひサポーターに登録いただき、理想のマンションの実現にご協力いただけますと幸いです。',
  },
  {
    question: 'エリアや賃料、面積などの条件は、現在掲載されているものに限られますか？',
    answer:
      '現在掲載している「妄想マンション」はあくまでサンプルです。事業化検討中のため、エリアや賃料、面積は確定ではございません。皆さんのご意見を参考にしながら、具体的に組み立てていきます。',
  },
  {
    question: '犬専用のマンション、猫専用のマンションもあるのでしょうか？',
    answer: '将来的には犬専用・猫専用のマンション構想も考えています。皆さんの声やニーズに応じて検討していきます。',
  },
  {
    question: '犬・猫以外のペットも飼うことはできますか？',
    answer: 'はい、犬・猫以外の小動物等も大歓迎です。',
  },
  {
    question: '大型犬や多頭飼育も可能ですか？',
    answer:
      'はい、大型犬や多頭飼育についても、条件を満たせばご相談いただけます。具体的なルールや制限については、建築が決まった際にご案内いたします。',
  },
  {
    question: '入居に際し、ワクチン接種は必須でしょうか？',
    answer:
      'はい、ワクチン接種や感染症対策は入居条件となります。抗体価検査を含め、入居者全員に安心を提供するための審査を行います。',
  },
  {
    question: '吠え癖や噛み癖のある犬でも入居できますか？',
    answer:
      '入居前に飼育状況を確認させていただきます。シッターや他の入居者との相談を通じて、対策や支援を得られる環境づくりを目指しています。',
  },
];
