import React, { useEffect, useRef, useState } from 'react';
/** plugin, lib **/
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import classNames from 'classnames';
/** styles **/
import s from '../../styles/organisms/area-floor-plan-rent.module.scss';
/** images **/
import LikeButtonIcon from 'assets/images/like-button-icon.webp';
import { ReactComponent as BlackCircleArrow } from 'assets/images/black-circle-arrow.svg';

type Props = {
  floorPlanRentList: any[];
  ownLikedIdList: string[];
  likeCountList: any[];
  slideToIndex: number;
  onClickLikeButton: (id: string) => void;
  onChangeSliderIndex: (index: number) => void;
};

export const AreaFloorPlanRentSlider: React.FC<Props> = ({
  floorPlanRentList,
  ownLikedIdList,
  likeCountList,
  slideToIndex,
  onClickLikeButton,
  onChangeSliderIndex,
}) => {
  const swiperRef = useRef<SwiperCore>();
  const [swiperIsBeginning, setSwiperIsBeginning] = useState<boolean>(true);
  const [swiperIsEnd, setSwiperIsEnd] = useState<boolean>(false);

  const SwiperButtonPrev = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slidePrev()} disabled={swiperIsBeginning}>
        <BlackCircleArrow className={classNames(s.navButton, s.prevButton)} />
      </button>
    );
  };

  const SwiperButtonNext = (): JSX.Element => {
    return (
      <button onClick={() => swiperRef.current?.slideNext()} disabled={swiperIsEnd}>
        <BlackCircleArrow className={classNames(s.navButton, s.nextButton)} />
      </button>
    );
  };

  const likeCountView = (id: number): number => {
    const res = likeCountList.find((item) => item.id === id);
    if (res) {
      return res.likeCount;
    }
    return 0;
  };

  const isLiked = (id: string) => {
    if (ownLikedIdList.includes(id)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    swiperRef.current?.slideTo(slideToIndex);
  }, [slideToIndex]);

  return (
    <div className={s.sliderWrapper}>
      <SwiperButtonPrev />
      <SwiperButtonNext />
      {floorPlanRentList && (
        <Swiper
          onBeforeInit={(swiper: any) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={54}
          speed={700}
          slidesPerView={1}
          onSlideChange={(swiper: any) => {
            setSwiperIsBeginning(swiper.isBeginning);
            setSwiperIsEnd(swiper.isEnd);
            onChangeSliderIndex(swiper.activeIndex);
          }}
        >
          {floorPlanRentList.map((area, index) => (
            <SwiperSlide key={index}>
              <div className={s.floorPlanType}>
                <p className={s.typeName}>
                  {area.type} {area.floorPlanType} タイプ
                </p>
                <p className={classNames(s.note, s.pcOnly)}>
                  事業化検討中につき実際に物件が存在する訳ではございません。
                  <br />
                  写真はイメージです。
                </p>
              </div>
              <div className={s.contentsInner}>
                {area.plans.map((item: any): React.JSX.Element | undefined => {
                  return (
                    <div className={s.areaItem} key={item.id}>
                      <div className={s.imageWrapper}>
                        <img src={require(`assets/images/${item.image}`)} alt={item.area} />
                        {item.label && <div className={s.label}>{item.label}</div>}

                        <button
                          className={classNames(s.like, isLiked(item.id) ? s.active : '')}
                          onClick={() => onClickLikeButton(item.id)}
                        >
                          <img src={LikeButtonIcon} alt="" />
                          住みたい<span>{likeCountView(item.id)}</span>
                        </button>
                      </div>
                      <div className={s.text}>
                        <p>{item.area}</p>
                        <div className={s.row}>
                          <p>賃料：{item.rent}</p>
                          <p>間取り：{item.floorPlan}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          ))}

          {/* <SwiperSlide>
            <div className={s.floorPlanType}>
              <p className={s.typeName}>1LDK タイプ</p>
              <p className={classNames(s.note, s.pcOnly)}>
                事業化検討中につき実際に物件が存在する訳ではございません。
                <br />
                写真はイメージです。
              </p>
            </div>
            <div className={s.contentsInner}>
              {floorPlanRentList.map((item: any): React.JSX.Element | undefined => {
                if (item.floorPlanType === '1LDK') {
                  return (
                    <div className={s.areaItem} key={item.id}>
                      <div className={s.imageWrapper}>
                        <img src={require(`assets/images/${item.image}`)} alt={item.area} />
                        {item.label && <div className={s.label}>{item.label}</div>}

                        <button
                          className={classNames(s.like, isLiked(item.id) ? s.active : '')}
                          onClick={() => onClickLikeButton(item.id)}
                        >
                          <img src={LikeButtonIcon} alt="" />
                          住みたい<span>{likeCountView(item.id)}</span>
                        </button>
                      </div>
                      <div className={s.text}>
                        <p>{item.area}</p>
                        <div className={s.row}>
                          <p>賃料：{item.rent}</p>
                          <p>間取り：{item.floorPlan}</p>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return undefined;
                }
              })}
            </div>
          </SwiperSlide> */}
        </Swiper>
      )}
    </div>
  );
};
