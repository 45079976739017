import React, { useRef } from 'react';
/** components **/
import { FadeUp, ParallaxItem } from 'components/organisms';

/** plugin, lib **/
import classNames from 'classnames';
import { useInViewFadeUp } from 'lib/use-in-view';
import { SERVICE_NAME } from '../../const';
/** images **/
import ConceptIllustration from 'assets/images/concept-illustration.webp';
import ConceptIllustrationSp from 'assets/images/concept-illustration-sp.webp';
import BgLogo from 'assets/images/bg-logo.svg';
/** styles **/
import s from '../../styles/organisms/concept.module.scss';

export const Concept: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  const inViewRef3 = useRef<HTMLHeadingElement>(null);
  const inViewRef4 = useRef<HTMLHeadingElement>(null);
  const inViewRef5 = useRef<HTMLHeadingElement>(null);
  const inViewRef6 = useRef<HTMLHeadingElement>(null);
  const inViewRef7 = useRef<HTMLHeadingElement>(null);
  const inViewRef8 = useRef<HTMLHeadingElement>(null);
  const inViewRef9 = useRef<HTMLHeadingElement>(null);
  const inViewRef10 = useRef<HTMLHeadingElement>(null);
  const inViewRef11 = useRef<HTMLHeadingElement>(null);
  const inViewRef12 = useRef<HTMLHeadingElement>(null);
  const inViewRef13 = useRef<HTMLHeadingElement>(null);
  const inViewRef14 = useRef<HTMLHeadingElement>(null);
  const inViewRef15 = useRef<HTMLHeadingElement>(null);
  const inViewRef16 = useRef<HTMLHeadingElement>(null);

  useInViewFadeUp(
    [
      inViewRef1,
      inViewRef2,
      inViewRef3,
      inViewRef4,
      inViewRef5,
      inViewRef6,
      inViewRef7,
      inViewRef8,
      inViewRef9,
      inViewRef10,
      inViewRef11,
      inViewRef12,
      inViewRef13,
      inViewRef14,
      inViewRef15,
      inViewRef16,
    ],
    {
      rootMargin: '-15% 0px -16% 0px',
    },
  );

  return (
    <div className={s.conceptWrapper}>
      <div className={s.leadText}>
        <p className={s.beforeFadeUp} ref={inViewRef1}>
          三菱地所が
        </p>
        <h4 className={s.beforeFadeUp} ref={inViewRef2}>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0s` }} ref={inViewRef5}>
            と
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.1s` }} ref={inViewRef6}>
            も
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.2s` }} ref={inViewRef7}>
            に
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.3s` }} ref={inViewRef8}>
            考
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.4s` }} ref={inViewRef9}>
            え
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.5s` }} ref={inViewRef10}>
            と
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.6s` }} ref={inViewRef11}>
            も
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.7s` }} ref={inViewRef12}>
            に
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.8s` }} ref={inViewRef13}>
            創
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `0.9s` }} ref={inViewRef14}>
            り
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `1s` }} ref={inViewRef15}>
            ま
          </span>
          <span className={s.beforeFadeUp} style={{ transitionDelay: `1.1s` }} ref={inViewRef16}>
            す
          </span>
        </h4>
        <p className={classNames(s.beforeFadeUp, s.detail)} ref={inViewRef3}>
          本プロジェクトは事業化検討中の段階です。
          <br className={s.spOnly} /> 皆さんのご意見をもとに事業化を進めてまいりますので、 <br />
          ぜひサポーターに登録いただき、理想のマンションの実現にご協力いただけますと幸いです。
        </p>
      </div>
      <FadeUp delay={0.2}>
        <img className={s.pcOnly} src={ConceptIllustration} alt="三菱地所がともに考えともに創ります" />
        <img className={s.spOnly} src={ConceptIllustrationSp} alt="三菱地所がともに考えともに創ります" />
      </FadeUp>
      <ParallaxItem className={s.parallaxBg}>
        <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
      </ParallaxItem>
    </div>
  );
};
