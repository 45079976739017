import React, { useRef } from 'react';
/* lib */
import { IssueList } from 'lib/issue';
import { useInViewFadeUp } from 'lib/use-in-view';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules';
import { ContentsTitle, IssueItem } from '../molecules';
/* styles */
import s from '../../styles/organisms/issues-in-pet-ownership.module.scss';

export const IssuesInPetOwnership: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  useInViewFadeUp([inViewRef1, inViewRef2]);

  const swiperRef = useRef<SwiperCore>();

  return (
    <div className={s.issuesInPetOwnershipWrapper}>
      <div className={s.inner}>
        <div className={s.beforeFadeUp} ref={inViewRef1}>
          <ContentsTitle className={s.contentsTitle} title={`よくあるお悩み`} />
        </div>

        <div className={s.beforeFadeUp} ref={inViewRef2}>
          <div className={s.sliderWrapper}>
            <Swiper
              className={s.sliderWrapper}
              onBeforeInit={(swiper: any) => {
                swiperRef.current = swiper;
              }}
              speed={6000}
              slidesPerView={1.3}
              centeredSlides
              loop={true}
              autoplay={{ delay: 0 }}
              modules={[Autoplay]}
              breakpoints={{
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 40,
                },
                840: {
                  slidesPerView: 2.5,
                  spaceBetween: 40,
                },
              }}
            >
              {IssueList.map((item, index) => (
                <SwiperSlide className={s.item} key={index}>
                  <IssueItem
                    title={item.title}
                    text={item.text}
                    image={item.image}
                    imageWidth={item.imageWidth}
                    key={index}
                  />
                </SwiperSlide>
              ))}
              {/* <div className={classNames(s.navWrapper, s.pcOnly)}>
                <SwiperButtonPrev />
                <SwiperButtonNext />
              </div> */}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};
