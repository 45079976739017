import React from 'react';
/** lib **/
import s from '../../styles/molecules/issue-item.module.scss';
/** style **/
import classNames from 'classnames';

type Props = {
  className?: string;
  text?: string;
  title?: string;
  image?: string;
  imageWidth?: number;
};
export const IssueItem: React.FC<Props> = ({ className, title, text, image, imageWidth }) => {
  return (
    <div className={classNames(className, s.issueItemWrapper)}>
      <div className={s.inner}>
        <p className={s.title}>{title}</p>
        <div className={s.imageWrapper}>
          <img src={require(`assets/images/${image}`)} alt={title} width={imageWidth} />
        </div>
      </div>
      <div className={s.text}>{text}</div>
    </div>
  );
};
