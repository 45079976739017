/* eslint-disable array-callback-return */
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
/** lib **/
import { SERVICE_NAME } from 'const';
import { useInViewFadeUp } from 'lib/use-in-view';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import classNames from 'classnames';
import { WorriesItemList } from 'lib/worries';
/** components **/
import { ContentsTitle, WorriesItem } from '../molecules';
import { ParallaxItem } from 'components/organisms';
/** styles **/
import s from '../../styles/organisms/worries.module.scss';
/** images **/
import { ReactComponent as GreenCircleArrow } from 'assets/images/green-circle-arrow.svg';
import BgLogo from 'assets/images/bg-logo.svg';

export const Worries: React.FC = () => {
  const inViewRef1 = useRef<HTMLHeadingElement>(null);
  const inViewRef2 = useRef<HTMLHeadingElement>(null);
  const inViewRef3 = useRef<HTMLHeadingElement>(null);
  const inViewRef4 = useRef<HTMLHeadingElement>(null);
  const inViewRef5 = useRef<HTMLHeadingElement>(null);
  const inViewRef6 = useRef<HTMLHeadingElement>(null);
  const inViewRef7 = useRef<HTMLHeadingElement>(null);
  const inViewRef8 = useRef<HTMLHeadingElement>(null);
  const inViewRef9 = useRef<HTMLHeadingElement>(null);
  useInViewFadeUp([
    inViewRef1,
    inViewRef2,
    inViewRef3,
    inViewRef4,
    inViewRef5,
    inViewRef6,
    inViewRef7,
    inViewRef8,
    inViewRef9,
  ]);
  return (
    <div className={s.worriesWrapper}>
      <div className={s.inner}>
        <div className={s.beforeFadeUp} ref={inViewRef1}>
          <ContentsTitle className={s.contentsTitle} title="WORRIES" subTitle="ペットの悩み" />
        </div>
        <div className={classNames(s.worriesList, s.spOnly, s.beforeFadeUp)} ref={inViewRef2}>
          <Swiper slidesPerView={1.4} loop centeredSlides spaceBetween={16}>
            {WorriesItemList.slice(0, 6).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <WorriesItem text={item.text} spec={item.spec} image={item.image} imageWidth={item.imageWidth} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={classNames(s.worriesList, s.pcOnly)}>
          {WorriesItemList.slice(0, 6).map((item, index) => {
            let curentRef;
            let delayStyle;
            switch (index) {
              case 0:
                curentRef = inViewRef3;
                break;
              case 1:
                curentRef = inViewRef4;
                delayStyle = s.delay01;
                break;
              case 2:
                curentRef = inViewRef5;
                delayStyle = s.delay02;
                break;
              case 3:
                curentRef = inViewRef6;
                break;
              case 4:
                curentRef = inViewRef7;
                delayStyle = s.delay01;
                break;
              case 5:
                curentRef = inViewRef8;
                delayStyle = s.delay02;
                break;
              default:
                curentRef = inViewRef9;
            }
            return (
              <div className={classNames(s.beforeFadeUp, delayStyle ? delayStyle : '')} ref={curentRef} key={index}>
                <WorriesItem text={item.text} spec={item.spec} imageWidth={item.imageWidth} image={item.image} />
              </div>
            );
          })}
        </div>
        <p className={s.note}>※ ペットの声はイメージです</p>
        <Link className={s.link} to={'/worries'}>
          ペットたちの悩みをもっとみる
          <GreenCircleArrow className={s.icon} />
        </Link>
      </div>
      <ParallaxItem className={s.parallaxBg}>
        <img src={BgLogo} alt={SERVICE_NAME} width={381} height={500} />
      </ParallaxItem>
    </div>
  );
};
